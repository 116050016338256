<template>
    <div class="router-links-container">
        <router-link
            v-for="link in routerLinks"
            :key="link.name"
            :to="link.link"
            :exact="link.link === '/'"
            class="router-link"
        >
            <img
                :src="link.image"
                :alt="link.name"
            >
            <span>{{ link.name }}</span>
        </router-link>
        <!--        <a-->
        <!--            :href="xmasVillageUrl"-->
        <!--            class="router-link"-->
        <!--        >-->
        <!--            <img-->
        <!--                :src="require('@/assets/images/nav-icons/xmas-tree.png')"-->
        <!--                alt="xmas tree"-->
        <!--            >-->
        <!--            <span>Xmas Village</span>-->
        <!--        </a>-->
    </div>
</template>

<script>
    import configMixin from '@/mixins/config'
    export default {
        mixins: [configMixin],
        data() {
            return {
                routerLinks: [],
                menuNames: {},
                menuImages: {}
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isLoggedIn
            },
            theming() {
                return this.$store.getters.getTheming
            },
            xmasVillageUrl() {
                return this.config.xmasVillageUrl
            }
        },
        watch: {
            theming: {
                handler(newVal) {
                    if (newVal) {
                        this.menuNames = newVal?.locale?.menu
                        this.menuImages = newVal?.images?.navIcons
                        this.prepareRoutes()
                    }
                },
                deep: true,
                immediate: true
            }
        },
        methods: {
            prepareRoutes() {
                const routes = this.$router.options.routes.filter(route => !!route?.meta?.menuOrder)
                routes.sort((a, b) => a.meta.menuOrder - b.meta.menuOrder)
                this.routerLinks = routes.map(route => ({
                    name: this.getRouterLinkName(route.name),
                    image: this.getRouterLinkImage(route.name),
                    link: route.path
                }))
            },
            getRouterLinkName(name) {
                return this.menuNames[name]
            },
            getRouterLinkImage(name) {
                return this.menuImages[name]
            }
        }
    }
</script>

<style scoped>

.router-links-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    height: 65px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: var(--ps-white);
    z-index: 1;
    padding-top: 1.35rem;
}

.router-link {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.3rem;
    align-items: center;
    font-weight: 600;
    font-size: 0.5rem;
    height: 61px;
    width: 64px;
    line-height: 9.83px;
    color: var(--ps-black);
    text-decoration: none;
}

.router-link img {
    height: 21px;
    width: 21px;
}

.router-link-active span,
.router-link-exact-active span {
    color: var(--ps-black);
    position: relative;
}

</style>
