<template>
    <div class="navbar-container">
        <div class="content-container">

            <div
                class="router-links-container desktop-only"
            >
                <router-link
                    v-for="link in routerLinks"
                    :key="link.name"
                    :to="link.link"
                    :exact="link.link === '/'"
                    class="router-link"
                >
                    <span>{{ link.name }}</span>
                </router-link>
                <!-- <a-->
                <!--     :href="xmasVillageUrl"-->
                <!--     class="router-link"-->
                <!-- >-->
                <!--     <span>Xmas Village</span>-->
                <!-- </a>-->
            </div>
            <a
                :href="responsiblePlayLink"
                target="_blank"
                class="responsible-play-container desktop-only"
            >
                <img
                    :src="playResponsiblyIcon"
                    alt="responsible play"
                >
            </a>
        </div>
    </div>
</template>

<script>
    import configMixin from '@/mixins/config'
    export default {
        mixins: [configMixin],
        data() {
            return {
                routerLinks: [],
                menuNames: {}
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isLoggedIn
            },
            theming() {
                return this.$store.getters.getTheming
            },
            playResponsiblyIcon() {
                return this.$store.getters.getLanguage === 'en' ? require('@/assets/images/nav-icons/play-responsibly-header-badge-en.svg') : require('@/assets/images/nav-icons/play-responsibly-header-badge-el.svg')
            },
            responsiblePlayLink() {
                return this.$store.getters.getLanguage === 'en' ? 'https://www.opapcsr.gr/en/our-commitment' : 'https://www.opapcsr.gr/h-desmeush-mas'
            },
            xmasVillageUrl() {
                return this.config.xmasVillageUrl
            }
        },
        watch: {
            theming: {
                handler(newVal) {
                    if (newVal) {
                        this.menuNames = newVal?.locale?.menu
                        this.prepareRoutes()
                    }
                },
                deep: true,
                immediate: true
            }
        },
        methods: {
            prepareRoutes() {
                const routes = this.$router.options.routes.filter(route => !!route?.meta?.menuOrder)
                routes.sort((a, b) => a.meta.menuOrder - b.meta.menuOrder)
                this.routerLinks = routes.map(route => ({
                    name: this.getRouterLinkName(route.name),
                    link: route.path
                }))
            },
            getRouterLinkName(name) {
                return this.menuNames[name]
            }
        }
    }
</script>

<style scoped>
.navbar-container {
    display: flex;
    justify-content: center;
    height: 58px;
    width: 100%;
    background: var(--ps-white);
    border-bottom: 1px solid rgba(87, 87, 87, 0.4);
}

.content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1286px;
    height: 100%;
}

.router-links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 2rem;
    position: relative;
}

.router-link {
    font-weight: 500;
    color: var(--ps-black);
    text-decoration: none;
}

.router-link-active span::after,
.router-link-exact-active span::after {
    content: '';
    position: absolute;
    top: 150%;
    left: 0;
    right: 0;
    height: 2px;
    width: 100%;
    background-color: var(--ps-black);
}

.router-link-active span,
.router-link-exact-active span {
    font-weight: 700;
    position: relative;
}

.responsible-play-container {
    position: relative;
}

.responsible-play-container > img {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100px;
}

</style>
